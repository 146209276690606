import Slider from 'react-slick'
import cn from '@utils/helpers/cn'
import { storyblokEditable } from '@storyblok/react'
import StoryblokComponent from '@components/Storyblok/StoryblokComponent'
import Arrow from '@components/ui/Arrows/Arrows'

const MuiSlider = ({ blok }) => {
  const settings = {
    lazyLoad: 'ondemand',
    arrows: blok.arrows,
    autoplay: blok.autoplay,
    autoplaySpeed: parseInt(blok.autoplaySpeed),
    centerMode: blok.centerMode,
    dots: blok.dots,
    draggable: blok.draggable,
    infinite: blok.infinite,
    pauseOnHover: blok.pauseOnHover,
    slidesToScroll: parseInt(blok.slidesToScroll),
    slidesToShow: parseInt(blok.slidesToShow),
    useTransform: false,
    swipeToSlide: true,
    nextArrow: blok.arrows ? <Arrow isSlider={true} /> : null,
    prevArrow: blok.arrows ? <Arrow isSlider={true} left /> : null,
    speed: blok.speed,
    responsive: blok.Responsive?.isResponsive
      ? [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: parseInt(blok.Responsive.desktop.slidesToShow),
              slidesToScroll: parseInt(blok.Responsive.desktop.slidesToScroll),
              infinite: blok.Responsive.desktop.infinite,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: parseInt(blok.Responsive.tablet.slidesToShow),
              slidesToScroll: parseInt(blok.Responsive.tablet.slidesToScroll),
              infinite: blok.Responsive.tablet.infinite,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: parseInt(blok.Responsive.mobile.slidesToShow),
              slidesToScroll: parseInt(blok.Responsive.mobile.slidesToScroll),
              infinite: blok.Responsive.mobile.infinite,
            },
          },
        ]
      : null,
  }
  return (
    <Slider
      {...settings}
      className={cn({ 'mb-10': blok.dots }, blok.class)}
      aria-hidden="true"
      {...storyblokEditable(blok)}
    >
      {blok.blocks
        ?.filter(
          (b) =>
            (b.hasOwnProperty('showBlock') && b.showBlock) ||
            !b.hasOwnProperty('showBlock'),
        )
        .map((nestedBlok, index) => (
          <StoryblokComponent
            index={index}
            blok={nestedBlok}
            key={nestedBlok._uid}
          />
        ))}
    </Slider>
  )
}

export default MuiSlider
